import React from "react";
import { VideoSection } from "../../VideoSection/videoSection";
import ScrollDownButton from "../../Buttons/ScrollDownButton";
import ScrollUpButton from "../../Buttons/ScrollUpButton";
import Section from "../../Section";
import { CardSection } from "../../CardSection/CardSection";
import ImageSection from "../../ImageSection";







export const Home = () => {
  const cards = [
    {
      title: "Desenvolvimento ERP",
      image: '/images/home/ERP.png',
      text: "Nossos serviços de desenvolvimento de sistemas ERP abrangem todos os aspectos do ciclo de vida do software, desde a concepção até a implementação e manutenção contínua. Na Wise Code Technologies, estamos comprometidos em oferecer soluções personalizadas e abrangentes que impulsionam a eficiência e o crescimento dos negócios.",
      icon: <span className="material-symbols-outlined">browse_activity</span>,
      path: "/service"
    },
    {
      title: "Desenvolvimento CRM",
      image: '/images/home/crm.png',
      text: "Acreditamos que a gestão eficaz do relacionamento com o cliente é essencial para o sucesso de qualquer negócio. É por isso que oferecemos serviços especializados de desenvolvimento de sistemas CRM, projetados para simplificar seus processos de vendas, melhorar o envolvimento e a retenção do cliente e impulsionar o crescimento e a rentabilidade da sua empresa.",
      icon: <span className="material-symbols-outlined">install_mobile</span>,
      path: "/service"
    },
    {
      title: "Desenvolvimento Mobile",
      image: '/images/home/mobile.png',
      text: "Reconhecemos o impacto transformador da tecnologia móvel no mundo dos negócios. É por isso que oferecemos serviços especializados de desenvolvimento mobile, projetados para impulsionar a inovação, melhorar a experiência do usuário e alcançar resultados tangíveis para sua empresa.",
      icon: <span class="material-symbols-outlined">
        computer
      </span>,
      path: "/service"
    },
    {
      title: "Desenvolvimento Web",
      image: '/images/home/web_development.png',
      text: "Somos especialistas no desenvolvimento de uma ampla variedade de projetos web, incluindo sites institucionais, landing pages, sites de portfólio e e-commerces. Combinamos criatividade, expertise técnica e uma abordagem centrada no cliente para criar soluções web sob medida que impulsionam o sucesso dos nossos clientes.",
      icon: <span class="material-symbols-outlined">
        computer
      </span>,
      path: "/service"
    },
  ];
  return (
    <div className="home_container">

      <main>
        <VideoSection />
        <ScrollDownButton />
        <ScrollUpButton />

        <section>
          <Section
            BackgroundImage='/images/home/software_developer.svg'
            title="Bem-vindo à Wise Code Technologies"
            text="Na Wise Code Technologies, acreditamos no poder
                  da inovação e da parceria estratégica para impulsionar
                  o sucesso. Nossa jornada começa com uma visão de conectividade
                  e crescimento, simbolizada pela integração perfeita da
                  tecnologia em todos os aspectos do mundo empresarial moderno."
            buttonTo='/about'
            buttonText='Saiba mais'
            showButton={true}
            showImage={true}
            imageStyle={{ 
              width: '50vw', 
              height: '500px', 
              backgroundSize: 'cover', 
              backgroundRepeat: 'no-repeat', 
              backgroundPosition: 'left' 
            }}
            textAlignment={{
              display: 'flex',
              margimTop: '100px',
              '@media screen and (max-width 768px)': {
                flexDirection: 'column',
                margimTop: '50px',
                display: 'flex'
              }
            }}
            sectionContent={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'justify',
              alignItems: 'center',              
              '@media screen and (max-width: 768px)': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',                
                padding: '1px',
                margimLeft: '100px'
              }
            }}
            sectionTitle={{ 
              display: 'flex',               
              fontSize: '36px', 
              fontFamily: 'saira', 
              marginBottom: '20px', 
              textAlign: 'justify', 
              color: '#f1f1f1',
              '@media screen and (max-width: 768px)': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',                
              }
            }}
            sectionText={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              fontSize: '18px', 
              fontFamily: 'saira',
              padding: '50px', 
              color: '#f1f1f1',
              '@media screen and (max-width: 768px)': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '1px'
              }
            }}
          />
        </section>

        <section>
          <Section
            BackgroundImage='/images/home/internet.png'
            title="Criando Soluções"
            text="Com uma combinação de criatividade e expertise técnica,
                  criamos soluções personalizadas para suas necessidades.
                  Seja navegando pelas complexidades do desenvolvimento de 
                  software ou orquestrando a estratégia de marketing digital,
                  estamos aqui para guiá-lo em cada etapa do caminho."
            buttonTo='/service'
            buttonText='Conheça nossos serviços'
            showButton={true}
            showImage={true}
            imageStyle={{ 
              width: '50vw', 
              height: '500px', 
              backgroundSize: 'cover', 
              backgroundRepeat: 'no-repeat', 
              backgroundPosition: 'left' 
            }}
            textAlignment={{              
              display: 'flex',
              margimTop: '100px',
              '@media screen and (max-width 768px)': {
                flexDirection: 'column',
                margimTop: '50px',
                marginBottom: '100px',
                display: 'flex'
              }
            }}
            sectionContent={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'justify',
              alignItems: 'center',              
              '@media screen and (max-width: 768px)': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',                
                padding: '1px',
                margimLeft: '100px'
              }
            }}
            sectionTitle={{ 
              display: 'flex',               
              fontSize: '36px', 
              fontFamily: 'saira', 
              marginBottom: '20px', 
              textAlign: 'justify', 
              color: '#f1f1f1',
              '@media screen and (max-width: 768px)': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                
              }
            }}
            sectionText={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              fontSize: '18px', 
              fontFamily: 'saira',
              padding: '50px', 
              color: '#f1f1f1',
              '@media screen and (max-width: 768px)': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '1px'
              }
            }}

          />
        </section>
        <div className="CardSectionContainer">
          <CardSection
            cards={cards}
          />
        </div>
        <section className="home_image_section">
          <ImageSection
            backgroundImg='/images/home/social_media.png'
            title='Administração de Redes Sociais'
            text='As redes sociais desempenham papel fundamental
                  no mundo dos negócios modernos.
                  É por isso que oferecemos serviços especializados
                  de administração de redes sociais, projetados para
                  aumentar o alcance, o engajamento e a presença digital da sua marca.'
            titleStyle={{ marginTop: '300px', textAlign: 'left' }}
            textStyle={{ textAlign: 'left', alignSelf: 'flex-end' }}
            imageStyle={{ marginTop: '100px', color: '#f1f1f1' }}
          />
        </section>
        <section>
          <Section
            BackgroundImage='/images/home/meet.png'
            title="Superando Barreiras na Comunicação"
            text="Na Wise Code Technologies, entendemos as dificuldades que podem surgir
                  na comunicação entre desenvolvedores e clientes no mundo do desenvolvimento
                  de software. Reconhecemos que os clientes muitas vezes têm dificuldade em                   
                  articular suas necessidades e expectativas, enquanto os desenvolvedores
                  podem encontrar desafios ao explicar processos técnicos de forma acessível.
                  Nossa abordagem única visa romper essas barreiras, facilitando a comunicação
                  entre todas as partes envolvidas."
            buttonTo='/about'
            buttonText='Saiba mais'
            showButton={true}
            showImage={true}
            imageStyle={{ 
              width: '50vw', 
              height: '500px', 
              backgroundSize: 'cover', 
              backgroundRepeat: 'no-repeat', 
              backgroundPosition: 'left' 
            }}
            textAlignment={{
              display: 'flex',
              margimTop: '100px',
              '@media screen and (max-width 768px)': {
                flexDirection: 'column',
                margimTop: '50px',
                display: 'flex'
              }
            }}
            sectionContent={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'justify',
              alignItems: 'center',              
              '@media screen and (max-width: 768px)': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',                
                padding: '1px',
                margimLeft: '100px'
              }
            }}
            sectionTitle={{ 
              display: 'flex',               
              fontSize: '36px', 
              fontFamily: 'saira', 
              marginBottom: '20px', 
              textAlign: 'justify', 
              color: '#f1f1f1',
              '@media screen and (max-width: 768px)': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '1px'
              }
            }}
            sectionText={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              fontSize: '18px', 
              fontFamily: 'saira',
              padding: '50px', 
              color: '#f1f1f1',
              '@media screen and (max-width: 768px)': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '1px'
              }
            }}
          />
        </section>
      </main>
    </div>
  );
}