import React, { useState, useEffect } from 'react';
import { useTransition, animated, useSpringRef } from '@react-spring/web';

import './CarrouselSocialMidea.css';

export const pages = [
    ({ style }) => <animated.div style={{ ...style, background: '#2F304E' }}>
        <div className='slide-container'>
            <h2 className='slide-title'>Administração de Mídias Sociais</h2>
            <p className='slide-text'>Reconhecemos o papel fundamental que as redes sociais desempenham no mundo dos negócios modernos. É por isso que oferecemos serviços especializados de administração de redes sociais, projetados para aumentar o alcance, o engajamento e a presença digital da sua marca.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>

    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#6A7581' }}>
        <div className='slide-container'>
            <h2>Soluções Personalizadas</h2>
            <p>
                Nossos serviços abrangem todas as facetas da administração de redes sociais, desde a criação de conteúdo até a análise de métricas e relatórios. Trabalhamos em estreita colaboração com sua equipe para entender sua marca, seus objetivos e seu público-alvo, criando estratégias personalizadas que impulsionam resultados tangíveis.

            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#2F304E' }}>
        <div className='slide-container'>
            <h2>Aumento do Engajamento</h2>
            <p>
                Envolve seus seguidores com conteúdo relevante, autêntico e envolvente que fortalece os laços com sua marca.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#6A7581' }}>
        <div className='slide-container'>
            <h2>Crescimento da Audiência</h2>
            <p>
                Expandir sua base de seguidores e aumentar o alcance da sua marca para novos públicos interessados em seus produtos ou serviços.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#2F304E' }}>
        <div className='slide-container'>
            <h2>Geração de Leads</h2>
            <p>
                Transforme seguidores em clientes potenciais, gerando leads qualificados por meio de estratégias de conversão eficazes.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#6A7581' }}>
        <div className='slide-container'>
            <h2>Tecnologia Avançada</h2>
            <p>
                Utilizamos ferramentas e tecnologias avançadas de análise e gestão de redes sociais para otimizar o desempenho da sua presença online. Monitoramos constantemente o desempenho das suas campanhas, ajustando estratégias conforme necessário para alcançar os melhores resultados possíveis.

            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#E3F4B5B' }}>
        <div className='slide-container'>
            <h2>Compromisso com a Excelência</h2>
            <p>
                Na Wise Code Technologies, estamos comprometidos em oferecer serviços de alta qualidade que atendam e superem as expectativas dos nossos clientes. Nosso foco na inovação, qualidade e satisfação do cliente nos coloca na vanguarda da administração de redes sociais.

            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#023D54' }}>
        <div className='slide-container'>
            <h2>Transforme sua Presença Online</h2>
            <p>
            Junte-se a nós em uma jornada de transformação digital. Permita que a Wise Code Technologies administre suas redes sociais, amplifique sua voz online e leve sua marca para o próximo nível de sucesso e visibilidade.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
];

export const CarrouselSocialMidea = () => {
    const [index, set] = useState(0);
    const onClick = () => set(state => (state + 1) % 8);
    const transRef = useSpringRef();
    const transitions = useTransition(index, {
        ref: transRef,
        keys: null,
        from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
        enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
        config: { duration: 500 }
    });
    useEffect(() => {
        transRef.start();
    }, [index, transRef]);
    return (
        <div className={`flex fill container`} onClick={onClick}>
            {transitions((style, i) => {
                const Page = pages[i];
                return <Page style={style} />;
            })}
        </div>
    );
}
