
import React from 'react';
import './Section.css';
import DefaultButton from '../Buttons/DefaultButton';




export const Section = ({ BackgroundImage, imageStyle, sectionTitle, text,title, textAlignment, buttonTo, buttonText, sectionText, showButton, showImage, sectionContent }) => {
    let Button = ({to}) => {
        return(
            <DefaultButton
            to={to}
            buttonText={buttonText} />
        )
    }
    return ( 
        <div className="section" style={textAlignment}>
            {showImage && (
                <div className="section_image" style={{ backgroundImage: `url(${BackgroundImage})`, ...imageStyle, backgroundSize:'cover' }}>              
                </div>
            )}
            <div className="section_content" style={sectionContent}>
                <h2 className="section_title" style={sectionTitle}>{title}</h2>
                <p className="section_text" style={ sectionText }>{text}</p>
                {showButton && <Button to={buttonTo} buttonText={buttonText}/>}
            </div>
        </div>
    );
}

