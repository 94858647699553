import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer_menu">
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/contact">Contato</Link></li>
                    <li className="service"><Link to="/service">Serviços</Link></li>
                </ul>
            </div>
            <div className="seo-text">
                <p>©2023 Wise Code Technologies. Todos os direitos reservados.</p>                
            </div>
        </footer>
    );
}
