import './App.css';
import Header from './Components/Header';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { AboutUs } from './Components/Pages/AboutUs/AboutUs';
import { Home } from './Components/Pages/Home/Home';
import Service from './Components/Pages/Service';
import Contact from './Components/Pages/Contact';
import Footer from './Components/Footer';




function App() {
  return (
    <Router>
    <div className="App">   
      <Header />
    <Routes>
      <Route path='/' Component={Home} />
      <Route path='/about' Component={AboutUs} />      
      <Route path='/service' Component={Service} />  
      <Route path='/contact' Component={Contact} />      
    </Routes>     
      <Footer />
    </div>
    </Router>
  );
}

export default App;
