import { useState } from "react"
import emailjs from '@emailjs/browser'
import './FormComponent.css'
import DefaultButton from "../Buttons/DefaultButton"
import { WhatsAppIcon } from "../WhatsAppIcon/WhatsAppIcon"



export const FormComponent = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    function sendEmail(e) {
        e.preventDefault();

        if(name === '' || email === '' || message === ''){
            alert('Favor preencher todos os campos');
            return;
        }

        const templateParams = {
            from_name: name, 
            message: message,
            email: email
        }

        emailjs.send("service_xm5hgki", "template_j7w53i5", templateParams, "5XnuhB41MEPEwFtIT").then((response) => {
            console.log("Email Enviado", response.status, response.text)
            setEmail('')
            setName('')
            setMessage('')
        }, (err) => {
            console.log("EROO: ", err)
        })
    }

    return (
        <div className="container">
          <h1 className="title">Contato</h1>          
    
          <form className="form" onSubmit={sendEmail}>
            <input 
              className="input"
              type="text"
              placeholder="Digite seu nome"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            
            <input 
              className="input"
              type="text"
              placeholder="Digite seu email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
    
            <textarea 
              className="textarea"
              placeholder="Digite sua mensagem..."
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />
    
            <DefaultButton 
                buttonText='Enviar'
                onClick={sendEmail}/>
          </form>
          <WhatsAppIcon />
    
        </div>
      );   
   
}