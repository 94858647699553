import React, { useState, useEffect } from 'react';
import { useTransition, animated, useSpringRef } from '@react-spring/web';

import './CarrouselCRM.css';

export const pages = [
    ({ style }) => <animated.div style={{ ...style, background: '#2F304E' }}>
        <div className='slide-container'>
            <h2 className='slide-title'>Desenvolvimento de Sistemas CRM</h2>
            <p className='slide-text'>Na Wise Code Technologies, acreditamos que a gestão eficaz do relacionamento com o cliente é essencial para o sucesso de qualquer negócio. É por isso que oferecemos serviços especializados de desenvolvimento de sistemas CRM, projetados para simplificar seus processos de vendas, melhorar o envolvimento e a retenção do cliente e impulsionar o crescimento e a rentabilidade da sua empresa.</p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>

    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#6A7581' }}>
        <div className='slide-container'>
            <h2>Soluções Personalizadas</h2>
            <p>
                Nossos serviços de desenvolvimento de sistemas CRM abrangem todos os aspectos do ciclo de vida do software, desde a concepção até a implementação e além. Trabalhamos em estreita colaboração com sua equipe para entender suas necessidades exclusivas e criar um sistema CRM sob medida que atenda aos seus objetivos de negócios.

            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#2F304E' }}>
        <div className='slide-container'>
            <h2>Simplificação de Processos</h2>
            <p>
                Automatize tarefas repetitivas e simplifique fluxos de trabalho complexos para aumentar a eficiência operacional.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#6A7581' }}>
        <div className='slide-container'>
            <h2>Melhoria do Envolvimento do Cliente</h2>
            <p>
                Capacite sua equipe a oferecer um atendimento ao cliente excepcional, personalizado e em tempo real.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#FF914D' }}>
        <div className='slide-container'>
            <h2>Aumento da Retenção de Clientes</h2>
            <p>
                Cultive relacionamentos sólidos com os clientes e aumente a fidelidade à marca com estratégias de engajamento proativas.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#E3F4B5B' }}>
        <div className='slide-container'>
            <h2>Impulsionar o Crescimento</h2>
            <p>
                Identifique oportunidades de vendas, preveja tendências de mercado e tome decisões informadas para impulsionar o crescimento e a rentabilidade.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#023D54' }}>
        <div className='slide-container'>
            <h2>Compromisso com a Excelência</h2>
            <p>
                Na Wise Code Technologies, estamos dedicados a oferecer serviços de alta qualidade que superem as expectativas dos nossos clientes. Nosso foco na inovação, qualidade e satisfação do cliente nos coloca à frente na vanguarda do desenvolvimento de sistemas ERP.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#FF6100' }}>
        <div className='slide-container'>
            <h2>Transforme seu Relacionamento com o Cliente</h2>
            <p>
                Junte-se a nós em uma jornada de transformação do relacionamento com o cliente. Permita que a Wise Code Technologies desenvolva um sistema CRM personalizado que impulsione o sucesso do seu negócio e crie laços duradouros com seus clientes.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
];

export const CarrouselCRM = () => {
    const [index, set] = useState(0);
    const onClick = () => set(state => (state + 1) % 8);
    const transRef = useSpringRef();
    const transitions = useTransition(index, {
        ref: transRef,
        keys: null,
        from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
        enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
        config: { duration: 500 }
    });
    useEffect(() => {
        transRef.start();
    }, [index, transRef]);
    return (
        <div className={`flex fill container`} onClick={onClick}>
            {transitions((style, i) => {
                const Page = pages[i];
                return <Page style={style} />;
            })}
        </div>
    );
}
