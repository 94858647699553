import { useEffect, useState } from 'react';
import './Header.css'
import { IconButton } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom';


export const Header = () => {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const [headerOpacity, setHeaderOpacity] = useState(0);

    useEffect(() => {
        let timeoutId;

        const handleScroll = () => {
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                const scrollPosition = window.scrollY;
                const windowHeight = window.innerHeight;
                const opacity = Math.min(scrollPosition / (windowHeight / 2), 0.6);

                setHeaderOpacity(opacity);
            }, 100); 
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(timeoutId);
        };
    }, []);
    return (
        <header className="header" style={{ backgroundColor: `rgba(78, 73, 73, ${headerOpacity})` }}>

            <h1 className="header_title">
                <Link to='/'>
                    <img src={'images/header/LogoWiseCodeBranco.svg'} alt="Logo" /> { }
                </Link>
            </h1>
            <nav className="header_menu">
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/service">Serviços</Link></li>
                    <li><Link to="/contact">Contato</Link></li>
                    <li><Link to="/about">Sobre nós</Link></li>
                </ul>
            </nav>
            <div className="header_icons">
                <IconButton aria-label="instagram">
                    <InstagramIcon />
                </IconButton>
                <a href="https://wa.me/5511971808258" target="_blank" rel="noopener noreferrer">
                    <IconButton aria-label="whatsapp">
                        <WhatsAppIcon />
                    </IconButton>
                </a>
            </div>
            <nav className={`mobile-menu ${showMenu ? 'open' : ''}`}>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/service">Serviços</Link></li>
                    <li><Link to="/contact">Contato</Link></li>
                    <li><Link to="/about">Sobre nós</Link></li>
                </ul>
            </nav>
            <div className={`menu-icon ${showMenu ? 'open' : ''}`} onClick={toggleMenu}>
                <div className="line line1" />
                <div className="line line2" />
                <div className="line line3" />
            </div>
        </header>

    );
}