import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardMedia, Collapse } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './CardSection.css';

const CardContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    gap: '50px',    
    overflowX: 'auto',
    maxWidth: '100%',
    '@media screen and (max-width: 768px)': {
        flexWrap: 'wrap'
    }
});

export const CardSection = ({ cards }) => {
    const navigate = useNavigate();
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    return (
        <CardContainer>
            {cards.map((card, index) => (
                <Card
                sx={{
                    maxWidth: 'calc(25% - 50px)',
                    '@media screen and (max-width: 768px)': {
                      maxWidth: 'calc(100% - 50px)'                     
                    },
                    ...card.styles
                  }}
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                >
                    <CardHeader icon={card.icon} title={card.title} />
                    <CardMedia
                        component="img"
                        height="194"
                        image={card.image}
                        alt="Imagem do card"
                        onClick={() => navigate(card.path)}
                        style={{ cursor: 'pointer' }}
                        className="card-image"
                    />
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            {card.text}
                        </Typography>
                    </CardContent>
                    <div className={`card-overlay ${hoveredIndex === index ? 'show' : ''}`}>
                        <span className="saiba-mais">Saiba Mais</span>
                    </div>
                    <Collapse in={hoveredIndex === index} timeout="auto" unmountOnExit>
                        <CardContent></CardContent>
                    </Collapse>
                </Card>
            ))}
        </CardContainer>
    );
};
