import './ScrollDownButton.css'

export const ScrollDownButton = () => {
    const scrollToBottom = () => {
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth'
        });
      };
    return (
        <button className="scroll-button" onClick={scrollToBottom}>
         <span className="arrow-down">&#8964;</span>
      </button>

    );
}