import React, { useRef, forwardRef } from "react";
import { Parallax, ParallaxLayer } from '@react-spring/parallax';

export const AnimatedComponent = ({ pagesContent}, ref) => {
  const parallax = useRef(null);

  const scrollToNextPage = (pageIndex) => {
    parallax.current.scrollTo(pageIndex)
  }

  return (
    <div style={{ width: '100%', height: '100%', background: '#253237' }}>
      <Parallax ref={parallax} pages={pagesContent.length}>        
        {pagesContent.map((content, index) => (
          <ParallaxLayer
            key={index}
            offset={index}
            speed={1}
            style={{ backgroundColor: content.backgroundColor }}
            onClick={() => {
              const nextPageIndex = index + 1;
              scrollToNextPage(nextPageIndex === pagesContent.length ? 0 : nextPageIndex);
            }}
          >
            {content.component}
          </ParallaxLayer>
        ))}
      </Parallax>
    </div>
  );
};

export default forwardRef(AnimatedComponent);
