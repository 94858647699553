import React, { useState, useEffect } from 'react';
import { useTransition, animated, useSpringRef } from '@react-spring/web';

import './CarrouselComponent.css';

export const pages = [
    ({ style }) => <animated.div style={{ ...style, background: '#FF6100' }}>
        <div className='slide-container'>
            <h2 className='slide-title'>Desenvolvimento de Sistemas ERP</h2>
            <p className='slide-text'>Nossos serviços de desenvolvimento de sistemas ERP abrangem todos os aspectos do ciclo de
                vida do software, desde a concepção até a implementação e manutenção contínua. Na Wise Code
                Technologies, estamos comprometidos em oferecer soluções personalizadas e abrangentes que
                impulsionam a eficiência e o crescimento dos negócios.</p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>

    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#023D54' }}>
        <div className='slide-container'>
        <h2>Design de Sistema</h2>
        <p>
            Trabalhamos em estreita colaboração com você para entender suas necessidades específicas e projetar um sistema ERP sob medida para o seu negócio.
        </p>
        <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#E3F4B5B' }}>
        <div className='slide-container'>
        <h2>Implementação</h2>
        <p>
            Nossa equipe altamente qualificada e experiente garante uma implementação suave e eficiente do sistema em toda a sua organização.
        </p>
        <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#FF914D' }}>
        <div className='slide-container'>
        <h2>Customização</h2>
        <p>
            Adaptamos o sistema às suas necessidades exclusivas, adicionando funcionalidades personalizadas que otimizam seus processos de negócios.
        </p>
        <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#6A7581' }}>
        <div className='slide-container'>
        <h2>Suporte e Manutenção</h2>
        <p>
            Fornecemos suporte contínuo e manutenção para garantir que seu sistema ERP funcione de forma ideal e atenda às suas necessidades em constante evolução.
        </p>
        <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#2F304E' }}>
        <div className='slide-container'>
        <h2>Tecnologia Avançada</h2>
        <p>
            Utilizamos as mais recentes tecnologias e ferramentas de desenvolvimento para criar sistemas ERP escaláveis, flexíveis e adaptáveis. Nosso objetivo é fornecer soluções que impulsionem a eficiência operacional, promovam a colaboração e capacitem seu negócio a se destacar em um ambiente competitivo.
        </p>
        <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#6A7581' }}>
        <div className='slide-container'>
        <h2>Compromisso com a Excelência</h2>
        <p>
            Na Wise Code Technologies, estamos dedicados a oferecer serviços de alta qualidade que superem as expectativas dos nossos clientes. Nosso foco na inovação, qualidade e satisfação do cliente nos coloca à frente na vanguarda do desenvolvimento de sistemas ERP.
        </p>
        <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#2F304E' }}>
        <div className='slide-container'>
        <h2>Transforme seu Negócio</h2>
        <p>
            Junte-se a nós em uma jornada de transformação digital. Permita que a Wise Code Technologies desenvolva um sistema ERP sob medida que impulsione o crescimento e o sucesso do seu negócio.
        </p>
        <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
];

export const CarrouselComponent = () => {
    const [index, set] = useState(0);
    const onClick = () => set(state => (state + 1) % 8);
    const transRef = useSpringRef();
    const transitions = useTransition(index, {
        ref: transRef,
        keys: null,
        from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
        enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
        config: {duration: 500}
    });
    useEffect(() => {
        transRef.start();
    }, [index, transRef]);
    return (
        <div className={`flex fill container`} onClick={onClick}>
            {transitions((style, i) => {
                const Page = pages[i];
                return <Page style={style} />;
            })}
        </div>
    );
}
