import React from "react";
import './TextAnimation.css'



export const TextAnimation = () => {

    return (
        <div className="text_container">
            <div className="text_animation">
                <p>
                    Click na Tela
                    <span>
                        Wise Code Technologies
                    </span>
                    &mdash; O futuro é agora &mdash;
                </p>

            </div>

        </div>
    )

}