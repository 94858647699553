import React, { useState, useEffect } from 'react';
import { useTransition, animated, useSpringRef } from '@react-spring/web';

import './CarrouselMobile.css';

export const pages = [
    ({ style }) => <animated.div style={{ ...style, background: '#FF6100' }}>
        <div className='slide-container'>
            <h2 className='slide-title'>Desenvolvimento de Aplicativos Mobile</h2>
            <p className='slide-text'>Na Wise Code Technologies, reconhecemos o impacto transformador da tecnologia móvel no mundo dos negócios. É por isso que oferecemos serviços especializados de desenvolvimento mobile, projetados para impulsionar a inovação, melhorar a experiência do usuário e alcançar resultados tangíveis para sua empresa.</p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>

    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#023D54' }}>
        <div className='slide-container'>
            <h2>Soluções Sob Medida</h2>
            <p>
                Nossos serviços abrangem todo o ciclo de vida do desenvolvimento de aplicativos móveis, desde a concepção até a implementação e manutenção contínua. Trabalhamos em estreita colaboração com sua equipe para entender suas necessidades específicas e criar aplicativos móveis sob medida que atendam aos seus objetivos de negócios.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#E3F4B5B' }}>
        <div className='slide-container'>
            <h2>Aumento do Envolvimento do Cliente</h2>
            <p>
                Capacite seus clientes a interagir com sua marca de forma conveniente e intuitiva, onde quer que estejam.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#FF914D' }}>
        <div className='slide-container'>
            <h2>Melhoria da Eficiência Operacional</h2>
            <p>
                Simplifique processos internos, aumente a produtividade da equipe e reduza os custos operacionais com soluções móveis personalizadas.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#6A7581' }}>
        <div className='slide-container'>
            <h2>Expansão do Alcance</h2>
            <p>
                Aproveite o poder dos dispositivos móveis para alcançar novos públicos, expandir sua base de clientes e aumentar a visibilidade da sua marca.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#2F304E' }}>
        <div className='slide-container'>
            <h2>Fidelização do Cliente:</h2>
            <p>
                Construa relacionamentos duradouros com seus clientes, oferecendo experiências móveis excepcionais e personalizadas que os mantêm voltando para mais.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#2F304E' }}>
        <div className='slide-container'>
            <h2>Tecnologia Avançada</h2>
            <p>
                Utilizamos as mais recentes tecnologias e ferramentas de desenvolvimento para criar sistemas ERP escaláveis, flexíveis e adaptáveis. Nosso objetivo é fornecer soluções que impulsionem a eficiência operacional, promovam a colaboração e capacitem seu negócio a se destacar em um ambiente competitivo.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
    ({ style }) => <animated.div style={{ ...style, background: '#6A7581' }}>
        <div className='slide-container'>
            <h2>Compromisso com a Excelência</h2>
            <p>
                Na Wise Code Technologies, estamos dedicados a oferecer serviços de alta qualidade que superem as expectativas dos nossos clientes. Nosso foco na inovação, qualidade e satisfação do cliente nos coloca à frente na vanguarda do desenvolvimento de sistemas ERP.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,

    ({ style }) => <animated.div style={{ ...style, background: '#2F304E' }}>
        <div className='slide-container'>
            <h2>Transforme sua experiência móvel</h2>
            <p>
                Junte-se a nós em uma jornada de transformação digital. Permita que a Wise Code Technologies desenvolva aplicativos móveis personalizados que cativem seus clientes, impulsionem o crescimento do seu negócio e o posicionem para o sucesso no mundo móvel de hoje.
            </p>
            <p className='slide-obs'>
                click na tela!
            </p>
        </div>
    </animated.div>,
];

export const CarrouselMobile = () => {
    const [index, set] = useState(0);
    const onClick = () => set(state => (state + 1) % 8);
    const transRef = useSpringRef();
    const transitions = useTransition(index, {
        ref: transRef,
        keys: null,
        from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
        enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
        config: { duration: 500 }
    });
    useEffect(() => {
        transRef.start();
    }, [index, transRef]);
    return (
        <div className={`flex fill container`} onClick={onClick}>
            {transitions((style, i) => {
                const Page = pages[i];
                return <Page style={style} />;
            })}
        </div>
    );
}
