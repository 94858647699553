import React from 'react';
import './ImageSection.css';



export const ImageSection = ({backgroundImg, title, text, titleStyle, textStyle, imageStyle}) => {

        return (
        <div className="ImagenSection">
            <div className='image_background' style={{backgroundImage: `url( ${backgroundImg})`}}>   
            { } 
            </div>                 
            <div className="ImageSection_content" style={imageStyle}>
                <h1 className="ImageSection_title" style={titleStyle}>{title}</h1>
                <p className="ImageSection_text" style={textStyle}>{text}</p>
            </div>              
        </div>
    );
}

