import React from "react"
import { Link } from "react-router-dom";
import './DefaultButton.css';

export const DefaultButton = ({to, buttonText, onClick}) => {

    return (
        
        <Link to={to} className="more_about_button" onClick={onClick}>{buttonText}</Link>

    )
};



