
import AnimatedComponent from "../../AnimatedComponent";
import ImageSection from "../../ImageSection";
import TextAnimation from "../../TextAnimation";


export const AboutUs = () => {
  
  const pagesContent = [
    {
      backgroundColor: '#28282A',
      component: <TextAnimation />,
    },
    {
      backgroundColor: '#87BCDE',
      component: (
        <ImageSection
          backgroundImg='/images/about/history.png'
          title='Nossa História'
          text='"A Wise Code foi fundada em 2022 com a missão de oferecer soluções digitais integradas e personalizadas para empresas de 
          todos os segmentos. Com uma equipe dedicada e altamente qualificada, a empresa trabalha em parceria com seus clientes para 
          desenvolver estratégias personalizadas que geram resultados tangíveis. Guiada pelos valores de excelência, inovação, parceria,
          integridade e resultados, a Wise Code busca continuamente se destacar no mercado digital, venha fazer parte da nossa história."'
          titleStyle={{ 
            marginTop: '200px', 
            textAlign: 'left', 
            marginLeft: '20px',
            '@media screen and (max-width)':{
              marginLeft: '5px',
              display: 'flex',
              textAlign: 'center'

            }
           }}
          textStyle={{ 
            textAlign: 'left', 
            alignSelf: 'flex-end',
             marginLeft: '20px',
             '@media screen and (max-width)':{
              marginLeft: '5px',
              display: 'flex',
              textAlign: 'center'

            }
            }}
          imageStyle={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      ),
    },
    {
      backgroundColor: '#87BCDE',
      component: (
        <ImageSection
          backgroundImg='/images/about/mission.png'
          title='Missão'
          text=' "Nossa missão na Wise Code é fornecer soluções digitais inovadoras e
         personalizadas que capacitam nossos clientes a alcançarem seu máximo 
         potencial online. Estamos comprometidos em oferecer serviços de alta 
         qualidade, atendimento excepcional e resultados tangíveis, ajudando nossos
          clientes a crescer e prosperar em um mundo digital em constante evolução." '
          titleStyle={{ marginTop: '200px', textAlign: 'left', marginLeft: '20px' }}
          textStyle={{ textAlign: 'left', alignSelf: 'flex-end', marginLeft: '20px' }}
          imageStyle={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      )
    },
    {
      backgroundColor: '#87BCDE',
      component: (
        <ImageSection
          backgroundImg='/images/about/vision.png'
          title='Visão'
          text=' "Nossa visão na Wise Code é ser reconhecidos como líderes em 
            desenvolvimento de software web e administração de redes sociais, fornecendo
            soluções tecnológicas de ponta que impulsionam o crescimento e o sucesso de 
            nossos clientes. Estamos comprometidos em continuar inovando e expandindo 
            nossas capacidades para atender às necessidades em constante mudança do 
            mercado digital." '
          titleStyle={{ marginTop: '200px', textAlign: 'left', marginLeft: '20px' }}
          textStyle={{ textAlign: 'left', alignSelf: 'flex-end', marginLeft: '20px' }}
          imageStyle={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      )
    },
    {
      backgroundColor: '#87BCDE',
      component: (
        <ImageSection
          backgroundImg='/images/about/valous.png'
          title='Valores'
          text=' "Buscamos a excelência em tudo o que fazemos, desde o atendimento ao cliente até a entrega de soluções digitais de alta qualidade,
        valorizamos a inovação e estamos sempre procurando maneiras de melhorar e oferecer novas soluções tecnológicas aos nossos clientes,
        acreditamos na construção de relacionamentos de longo prazo com nossos clientes, baseados na confiança, transparência e colaboração,
        agimos com integridade em todas as nossas interações, mantendo os mais altos padrões éticos e profissionais e
        estamos comprometidos em fornecer resultados mensuráveis e tangíveis que agreguem valor aos negócios de nossos clientes." '
          titleStyle={{ marginTop: '200px', textAlign: 'left', marginLeft: '20px' }}
          textStyle={{ textAlign: 'left', alignSelf: 'flex-end', marginLeft: '20px' }}
          imageStyle={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      )
    },
  ];

  return <AnimatedComponent pagesContent={pagesContent} />;
}
