import { CarrouselCRM } from '../../CarrouselComponent/CarrouselCRM/CarrouselCRM'
import { CarrouselComponent } from '../../CarrouselComponent/CarrouselERP/CarrouselComponent'
import { CarrouselMobile } from '../../CarrouselComponent/CarrouselMobile/CarrouselMobile'
import { CarrouselSocialMidea } from '../../CarrouselComponent/CarrouselSocialMidea/CarrouselSocialMidea'
import { TextComponent } from '../../TextComponent/TextComponent'
import './Service.css'


export const Service = () => {
    return (
        <main>
            <section className='text-section'>
                <TextComponent />
            </section>
            <section className='carousel-section'>
                <CarrouselComponent />
            </section>
            <section className='carousel-section'>
                <CarrouselCRM />
            </section>
            <section className='carousel-section'>
                <CarrouselMobile/>
            </section>
            <section className='carousel-section'>
                <CarrouselSocialMidea />
            </section>
            
        </main>

    )
}
