import React from 'react';
import './videoSection.css';

export const VideoSection = ({ title, text, titleStyle, textStyle }) => {
    return (
        <div className="VideoSection">
            <video className='video_background' autoPlay loop muted>
                <source src="/video/admin/video_capa_site.mp4" type="video/mp4" />
                Seu navegador não suporta a tag de vídeo.
            </video>
            <div className="VideoSection_content">
                <h1 className="VideoSection_title" style={titleStyle}>{title}</h1>
                <p className="VideoSection_text" style={textStyle}>{text}</p>
            </div>
        </div>
    );
}
